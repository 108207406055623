<template>
  <div>
    <v-container
      grid-list-lg
      fluid
    >
      <v-layout
        wrap
        justify-start
      >
        <v-flex
          md12
        >
          <v-card>
            <v-card-title class="headline primary white--text justify-center">
              Mcity Track Training
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex
          md12
        >
          <v-card>
            <v-card-title class="headline primary white--text">
              Safety Orientation Checklist
            </v-card-title>
            <v-card-text>
              <iframe
                src="https://drive.google.com/file/d/137I2EAjzvJ_7qB1lun-bZ0EdkyBJSsfY/preview"
                width="640"
                height="480"
              />
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          md12
        >
          <v-card>
            <v-card-title class="headline primary white--text">
              Safety Orientation Video
            </v-card-title>
            <v-card-text>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/vkWgSD7IGnc"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          md12
        >
          <v-card>
            <v-card-title class="headline primary white--text">
              Contact Information
            </v-card-title>
            <v-card-text>
              <v-form @submit.prevent>
                <v-text-field
                  v-model="phone"
                  label="Phone Number"
                  hint="ex. 5550123456"
                  mask="phone"
                  required
                  clearable
                  @input="$v.phoneNum.$touch()"
                  @blur="$v.phoneNum.$touch()"
                />
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          md12
        >
          <v-card>
            <v-card-title class="headline primary white--text">
              Safety Agreement
            </v-card-title>
            <v-card-text>
              <v-checkbox
                v-model="policyAgree"
                label="I affirm that I have watched the Mcity Safety Orientation Training Video, reviewed the Safety Orientation Checklist, and agree to abide by all facility rules and guidelines, including but not limited to the following:"
                class="black--text"
              />
              <ul>
                <li class="item">
                  No limit/emergency handling maneuvers.
                </li>
                <li class="item">
                  Follow safety/test plan as approved by Mcity.
                </li>
                <li class="item">
                  UofM is a non-smoking, weapons-free campus.
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex>
          <v-btn
            color="primary"
            :disabled="!policyAgree"
            @click="handleSubmit()"
          >
            Submit
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { numeric, required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'Training',
  data () {
    return {
      policyAgree: false,
      phone: ''
    }
  },
  methods: {
    ...mapActions('reservation/transactions', [
      'updateTrainingAction'
    ]),
    handleSubmit () {
      this.updateTrainingAction(this.phone)
      this.$router.push('/')
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    }),
    phoneNum: {
      get () {
        return this.phone
      },
      set (value) {
        this.phone = value
      }
    }
  },
  mounted () {
    this.phone = this.user.username || ''
  },
  validations: {
    phoneNum: {
      numeric,
      required,
      maxLength: maxLength(10)
    }
  }
}
</script>
<style>
  .item{
    text-align: left;
    font-size: 16px;
  }
  .theme--light.v-label {
    color: rgba(0,0,0,0.87);
}
</style>
